/* QUESTION */
.Learning-Route {
  font-family: 'Bangers', cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Learning-Route h2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 0px;
}

.Learning-Route-alert {
  color: red;
}

.Learning-Route-new-word {
  background-image: url('../../images/Notecard.svg');
  background-size: 300px 300px;
  background-position: 0 0 , center;
  height: 250px;
  width: 300px;
  text-align: center;
  font-size: 40px;
  padding: 20px 10px;
  color: rgba(104, 47, 226, 0.792);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.Learning-Route-new-word span {
  font-size: 50px;
  margin-top: 50px;
}

.Learning-Route form {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.Learning-Route label, input, button {
  margin: 10px 30px;
}

.Learning-Route input {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 18px;
}

.Learning-Route button {
  background: black;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 20px;
  text-align: center;
  font-family: 'Bangers', cursive;
}

.Learning-Route button:hover {
  background-color: rgba(104, 47, 226, 0.792);
}

.Learning-Route label {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 20px;
}

.Learning-Route-scores {
  background-image: url('../../images/Grid.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 90%;
  height: 260px;
  padding-top: 30px;
  margin-top: 40px;
}

.Learning-Route-scores p {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  font-size: 20px;
  font-family: 'Roboto Condensed', serif;
  font-weight: 800;
}

.Learning-Route-first-score {
  margin-top: 40px;
}

/* CORRECT ANSWER */
.DisplayScore-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Bangers', cursive;
}

.DisplayScore-container .correct {
  font-size: 50px;
  color: rgb(5, 168, 5);
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
}

.DisplayScore {
  background-color: lightgray;
  width: 100%;
  text-align: center;
  color: rgba(104, 47, 226, 0.792);
  font-size: 25px;

}

.DisplayFeedback {
  font-family: 'Gloria Hallelujah', cursive;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
}

.DisplayScore-container button {
  background: black;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 20px;
  text-align: center;
  font-family: 'Bangers', cursive;
}

.DisplayScore-container button:hover {
  background-color: rgba(104, 47, 226, 0.792);
}
/* INCORRECT ANSWER */
.DisplayScore-container .incorrect {
  font-size: 50px;
  color: rgb(241, 46, 79);
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
}
/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
  .Learning-Route-scores {
    width: 600px;
  }
}
