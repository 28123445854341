@import url(https://fonts.googleapis.com/css?family=Ubuntu);
.Header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    font-family: 'Bangers', cursive;
    font-size: 30px;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 5px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
  }
  
  .Header h1{
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
    text-decoration: none;
    color: black;
  }
  
  .Header h1 a:hover{
    color: rgba(104, 47, 226, 0.792);
  }

  .Header a{
      color: black;
      text-decoration: none;
  }

  .Header_not-logged-in {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    font-size: 16px;
    margin-right: 5px;
  }
  
  .Header_not-logged-in a {
    background: black;
    color: white;
    border-radius: 15px;
    padding: 5px 15px;
    margin-right: 10px;
    text-decoration: none;
    margin-top: 5px;
    font-size: 16px;
    text-align: center;
  }

  .Header_not-logged-in a:hover, .header_logged-in a {
    background-color: rgba(104, 47, 226, 0.792);
  }
  
  .Header_logged-in a {
    background: black;
    color: white;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 16px;
    text-align: center;
  }

  .Header_logged-in a:hover {
    background-color: rgba(104, 47, 226, 0.792);
  }

  .username-container{
      margin-bottom: 10px;
      text-align: center;
  }

  .logged-in-container{
      text-align: center;
      margin-right: 10px;
      font-size: 16px;
  }

  .logged-in-user-name {
    font-size: 25px;
    color: rgba(104, 47, 226, 0.792);
  }

  /* MEDIA QUERIES */

  @media only screen and (min-width: 600px) {
    .Header {
      -webkit-align-items: normal;
              align-items: normal;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      -webkit-flex-direction: row;
              flex-direction: row;
    }

    .Header h1 {
      text-align: left;
    }
  }
  
.registration-form{
    border: 1px solid white;
    text-align: center;
    font-size: 18px;
    background-image: url(/static/media/longnotecard.ecfa910e.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 90vw;
    color: black;
    margin-right: 20px;
    margin-left: 0px;
    margin-bottom: 30px;
    padding: 30px; 
    font-family: 'Gloria Hallelujah', cursive;
}

.registration-form input {
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 13px;
}

.Button{
    margin-top: 10px;
    background: black;
    color: white;
    font-family: 'Bangers', cursive;
    font-size: 18px;
    border-radius: 15px;
    padding: 5px 15px;
    margin-bottom: 1px;
    text-align: center;
    margin-bottom: 0px;
}

.Button:hover {
    background: rgba(104, 47, 226, 0.792);
}

.alert {
    margin-top: 64px;
    color: rgb(241, 46, 79);
}

.login-link{
    font-size: 16px;
    color: black;
}

.login-link:hover{
    color: rgba(104, 47, 226, 0.792);
}

.Label{
    padding: 10px;
}

.Input{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
}

footer {
   text-align: center;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
    .registration-form {
        background-image: url(/static/media/Notecard.4438dd38.svg);    
        background-size: 600px;
    }
    
    
}
.registration-title-container{
    text-align: center;
    margin-top: 50px;
    font-family: 'Bangers', cursive;
}

.registration-title-container p{
    font-family: 'Roboto Condensed', serif;
    font-size: 30px;
    padding-right: 15px;
    padding-left: 15px;
}

.registration-title-container h2 {
    font-size: 40px;
    margin-bottom: 0;
}
.LoginForm{
    text-align: center;
    font-size: 18px;
    background-image: url(/static/media/longnotecard.ecfa910e.svg);
    background-repeat: no-repeat;
    background-position: top;
    background-size: 90vw;
    color: black;
    margin-right: 20px;
    margin-left: 0px;
    margin-bottom: 30px;
    padding: 30px;
    font-family: 'Gloria Hallelujah', cursive; 
}

.alert-log-in {
    margin-top: 80px;
    color: rgb(241, 46, 79);
}

.LoginForm input {
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 13px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
    .LoginForm {
        background-image: url(/static/media/Notecard.4438dd38.svg);    
        background-size: 600px;
    }
}



.login-title-container{
    text-align: center;
    margin-top: 50px;
    font-family: 'Bangers', cursive;
}

.login-title-container h2 {
    font-size: 40px;
    margin-bottom: 0;
}

.dashboard {
  text-align: center;
  font-family: 'Gloria Hallelujah', cursive;
}

.dashboard h2 {
  font-family: 'Bangers', serif;
  font-size: 50px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.summary {
  background-color: lightgray;
  padding-top: 10px;
  padding-bottom: 15px;
}

.total-correct {
  font-family: 'Bangers', serif;
  background-color: rgba(104, 47, 226, 0.792);
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 30px;
  color: white;
}

.dashboard button {
  margin-top: 10px;
  background-color: black;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 25px;
  text-align: center;
  font-family: 'Bangers', cursive;
}

.dashboard button:hover {
  background-color: rgba(104, 47, 226, 0.792);
}

.dashboard h3 {
  font-family: 'Bangers', cursive;
  font-size: 35px;
}

.dashboard ul {
  list-style-type: none;
  padding-left: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: center;
          justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
} 

.dashboard ul li {
  background-image: url(/static/media/Sticky.b4c41966.svg);
  background-repeat: no-repeat;
  background-position: top, left;
  background-size:300px 300px;
  width: 300px;
  height: 300px;
  margin-right: 10px;
  margin-left: 10px;
}

.dashboard ul li h4 {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 50px;
  margin-bottom: 3px;
  margin-top: 25px;
}

.dashboard ul li p {
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 3px;
}
/* QUESTION */
.Learning-Route {
  font-family: 'Bangers', cursive;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.Learning-Route h2 {
  font-size: 30px;
  text-align: center;
  margin-bottom: 0px;
}

.Learning-Route-alert {
  color: red;
}

.Learning-Route-new-word {
  background-image: url(/static/media/Notecard.4438dd38.svg);
  background-size: 300px 300px;
  background-position: 0 0 , center;
  height: 250px;
  width: 300px;
  text-align: center;
  font-size: 40px;
  padding: 20px 10px;
  color: rgba(104, 47, 226, 0.792);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Learning-Route-new-word span {
  font-size: 50px;
  margin-top: 50px;
}

.Learning-Route form {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Learning-Route label, input, button {
  margin: 10px 30px;
}

.Learning-Route input {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 18px;
}

.Learning-Route button {
  background: black;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 20px;
  text-align: center;
  font-family: 'Bangers', cursive;
}

.Learning-Route button:hover {
  background-color: rgba(104, 47, 226, 0.792);
}

.Learning-Route label {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 20px;
}

.Learning-Route-scores {
  background-image: url(/static/media/Grid.8820f6fe.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 90%;
  height: 260px;
  padding-top: 30px;
  margin-top: 40px;
}

.Learning-Route-scores p {
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 5px;
  font-size: 20px;
  font-family: 'Roboto Condensed', serif;
  font-weight: 800;
}

.Learning-Route-first-score {
  margin-top: 40px;
}

/* CORRECT ANSWER */
.DisplayScore-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Bangers', cursive;
}

.DisplayScore-container .correct {
  font-size: 50px;
  color: rgb(5, 168, 5);
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
}

.DisplayScore {
  background-color: lightgray;
  width: 100%;
  text-align: center;
  color: rgba(104, 47, 226, 0.792);
  font-size: 25px;

}

.DisplayFeedback {
  font-family: 'Gloria Hallelujah', cursive;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
}

.DisplayScore-container button {
  background: black;
  color: white;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 20px;
  text-align: center;
  font-family: 'Bangers', cursive;
}

.DisplayScore-container button:hover {
  background-color: rgba(104, 47, 226, 0.792);
}
/* INCORRECT ANSWER */
.DisplayScore-container .incorrect {
  font-size: 50px;
  color: rgb(241, 46, 79);
  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
}
/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
  .Learning-Route-scores {
    width: 600px;
  }
}

.Not-Found {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  font-family: 'Bangers', cursive;
  font-size: 50px;
  color: rgb(241, 46, 79);
  margin-right: 20px;
  margin-left: 20px;
}

.Not-Found p {
  font-size: 40px;
  color: black;
}
main {
  margin-bottom: 55px;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --font-size: calc(10px + 1vmin);
}

