.Not-Found {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Bangers', cursive;
  font-size: 50px;
  color: rgb(241, 46, 79);
  margin-right: 20px;
  margin-left: 20px;
}

.Not-Found p {
  font-size: 40px;
  color: black;
}