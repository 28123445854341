.login-title-container{
    text-align: center;
    margin-top: 50px;
    font-family: 'Bangers', cursive;
}

.login-title-container h2 {
    font-size: 40px;
    margin-bottom: 0;
}
