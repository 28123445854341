.Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Bangers', cursive;
    font-size: 30px;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 5px;
    padding-bottom: 10px;
    border-bottom: solid 1px black;
  }
  
  .Header h1{
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 5px;
    text-decoration: none;
    color: black;
  }
  
  .Header h1 a:hover{
    color: rgba(104, 47, 226, 0.792);
  }

  .Header a{
      color: black;
      text-decoration: none;
  }

  .Header_not-logged-in {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    margin-right: 5px;
  }
  
  .Header_not-logged-in a {
    background: black;
    color: white;
    border-radius: 15px;
    padding: 5px 15px;
    margin-right: 10px;
    text-decoration: none;
    margin-top: 5px;
    font-size: 16px;
    text-align: center;
  }

  .Header_not-logged-in a:hover, .header_logged-in a {
    background-color: rgba(104, 47, 226, 0.792);
  }
  
  .Header_logged-in a {
    background: black;
    color: white;
    padding: 5px 15px;
    border-radius: 15px;
    font-size: 16px;
    text-align: center;
  }

  .Header_logged-in a:hover {
    background-color: rgba(104, 47, 226, 0.792);
  }

  .username-container{
      margin-bottom: 10px;
      text-align: center;
  }

  .logged-in-container{
      text-align: center;
      margin-right: 10px;
      font-size: 16px;
  }

  .logged-in-user-name {
    font-size: 25px;
    color: rgba(104, 47, 226, 0.792);
  }

  /* MEDIA QUERIES */

  @media only screen and (min-width: 600px) {
    .Header {
      align-items: normal;
      justify-content: space-between;
      flex-direction: row;
    }

    .Header h1 {
      text-align: left;
    }
  }
  