.registration-title-container{
    text-align: center;
    margin-top: 50px;
    font-family: 'Bangers', cursive;
}

.registration-title-container p{
    font-family: 'Roboto Condensed', serif;
    font-size: 30px;
    padding-right: 15px;
    padding-left: 15px;
}

.registration-title-container h2 {
    font-size: 40px;
    margin-bottom: 0;
}