.registration-form{
    border: 1px solid white;
    text-align: center;
    font-size: 18px;
    background-image: url('../../images/longnotecard.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 90vw;
    color: black;
    margin-right: 20px;
    margin-left: 0px;
    margin-bottom: 30px;
    padding: 30px; 
    font-family: 'Gloria Hallelujah', cursive;
}

.registration-form input {
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 13px;
}

.Button{
    margin-top: 10px;
    background: black;
    color: white;
    font-family: 'Bangers', cursive;
    font-size: 18px;
    border-radius: 15px;
    padding: 5px 15px;
    margin-bottom: 1px;
    text-align: center;
    margin-bottom: 0px;
}

.Button:hover {
    background: rgba(104, 47, 226, 0.792);
}

.alert {
    margin-top: 64px;
    color: rgb(241, 46, 79);
}

.login-link{
    font-size: 16px;
    color: black;
}

.login-link:hover{
    color: rgba(104, 47, 226, 0.792);
}

.Label{
    padding: 10px;
}

.Input{
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
}

footer {
   text-align: center;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
    .registration-form {
        background-image: url('../../images/Notecard.svg');    
        background-size: 600px;
    }
    
    
}