.LoginForm{
    text-align: center;
    font-size: 18px;
    background-image: url('../../images/longnotecard.svg');
    background-repeat: no-repeat;
    background-position: top;
    background-size: 90vw;
    color: black;
    margin-right: 20px;
    margin-left: 0px;
    margin-bottom: 30px;
    padding: 30px;
    font-family: 'Gloria Hallelujah', cursive; 
}

.alert-log-in {
    margin-top: 80px;
    color: rgb(241, 46, 79);
}

.LoginForm input {
    font-family: 'Gloria Hallelujah', cursive;
    font-size: 13px;
}

/* MEDIA QUERIES */
@media only screen and (min-width: 600px) {
    .LoginForm {
        background-image: url('../../images/Notecard.svg');    
        background-size: 600px;
    }
}


